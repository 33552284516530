// @ts-check

import TypoReporter from 'typo-reporter';

import i18n from './i18n.js';
import urls, { getGoogleFormUrl } from '../urls.js';

const initTypoReporter = async (rootNode, formId, locale) => {
  const { t } = i18n;

  const formUrl = getGoogleFormUrl(formId);
  const typoReporterEndpoint = [urls.corsProxyUrl, formUrl.toString()].join(
    '/',
  );
  const typo = new TypoReporter(
    {
      formId,
      endpointUrl: typoReporterEndpoint,
      locale,
      translations: {
        [locale]: {
          header: t('typoreporter.header'),
          messageLabel: t('typoreporter.messageLabel'),
          commentLabel: t('typoreporter.commentLabel'),
          send: t('typoreporter.send'),
          sending: t('typoreporter.sending'),
          cancel: t('typoreporter.cancel'),
          error: t('typoreporter.error'),
        },
      },
    },
    rootNode,
  );

  return typo;
};

export default initTypoReporter;
