// @ts-check

const urls = {
  googleDocsUrl: 'https://docs.google.com',
  corsProxyUrl: 'https://corsanywhere.hexlet.app',
};

export const getGoogleFormUrl = (formId) => {
  const formUrl = new URL(
    `/forms/d/e/${formId}/formResponse`,
    urls.googleDocsUrl,
  );
  formUrl.searchParams.set('embedded', 'true');
  return formUrl;
};

export default urls;
