import Rails from '@rails/ujs';
import Cookies from 'js-cookie';
import jstz from 'jstz';

import { Tab, Tooltip, Popover } from 'bootstrap';
import Cocooned from '@notus.sh/cocooned';

import initTypoReporter from '../lib/typoreporter.js';

import './application.scss';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true);
// const imagePath = (name) => images(name, true);

// https://github.com/railsware/js-routes#setup
// bin/rails js:routes

// NOTE: добавить объяснение зачем этот код
const timezone = jstz.determine();
Cookies.set('browser_time_zone', timezone.name(), { expires: 1, path: '/' });

function initBootstrapComponents() {
  const url = document.location;
  if (url.hash) {
    // TODO: ждем https://github.com/twbs/bootstrap/issues/25220
    const tabs = document.querySelectorAll(
      `[data-bs-toggle="tab"][href="${url.hash}"]`,
    );
    [...tabs].forEach((el) => console.log(Tab.getOrCreateInstance(el).show()));

    // NOTE: принципиально что оно работает через data-bs-target
    // В href должна быть полноценная ссылка с хешом. Это нужно для "открыть в новой вкладке" в упражнениях.
    const tabs2 = document.querySelectorAll(
      `[data-bs-toggle="tab"][data-bs-target="${url.hash}"]`,
    );
    [...tabs2].forEach((el) => Tab.getOrCreateInstance(el).show());
  }

  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]',
  );
  [...tooltipTriggerList].forEach(
    (el) => new Tooltip(el, { boundary: 'window' }),
  );

  const popoverTriggerList = document.querySelectorAll(
    '[data-bs-toggle="popover"]',
  );
  [...popoverTriggerList].forEach((el) => new Popover(el, { html: true }));
}

function initializeTypoReporter() {
  const rootNode = document.createElement('div');
  document.body.appendChild(rootNode);
  initTypoReporter(
    rootNode,
    window.gon.typoreporter_google_form_id,
    window.gon.language,
  );
}

function initalizeExternalLinks() {
  document.querySelectorAll('.external-link').forEach((el) => {
    el.addEventListener('click', () => {
      window.open(el.dataset.href, '_blank');
    });
    el.style.cursor = 'pointer'; // make it feel like a link
  });
}

document.addEventListener('DOMContentLoaded', () => {
  Cocooned.start();
  Rails.start();

  initBootstrapComponents();
  initializeTypoReporter();
  initalizeExternalLinks();
});
